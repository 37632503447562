export let mockCartObject = [
  {
    name: 'Soap Bar',
    productId: '15',
    parentCategoryId: '1',
    subCategoryId: '2',
    weight: '1.4',
    unit: 'oz',
  },
  {
    name: 'Shampoo',
    productId: '15',
    parentCategoryId: '1',
    subCategoryId: '2',
    weight: '1.4',
    unit: 'oz',
  },
  {
    name: 'Conditioner',
    productId: '15',
    parentCategoryId: '1',
    subCategoryId: '2',
    weight: '2.4',
    unit: 'oz',
  },
  {
    name: 'Hand Gel',
    productId: '15',
    parentCategoryId: '1',
    subCategoryId: '2',
    weight: '5.6',
    unit: 'oz',
  },
  {
    name: 'Aloe Vera',
    productId: '15',
    parentCategoryId: '1',
    subCategoryId: '2',
    weight: '2.6',
    unit: 'oz',
  },
  {
    name: 'Conditioner',
    productId: '15',
    parentCategoryId: '1',
    subCategoryId: '2',
    weight: '1.4',
    unit: 'oz',
  },
];

export let mockUserObject = {
  name: 'Clark Kent',
  email: 'Ckent@tdp.com',
  password: 'hunter2',
};

export let mockShippingOptionsObject = [
  {
    name: 'Standard',
    price: 5.99,
    days: 5,
    carrier: 'USPS',
    carrierId: '1',
    type: 'ground',
  },
  {
    name: 'Express',
    price: 25.99,
    days: 1,
    carrier: 'UPS',
    carrierId: '3',
    type: 'air',
  },
  {
    name: 'Overnight',
    price: 35.99,
    days: 1,
    carrier: 'DHL',
    carrierId: '4',
    type: 'air',
  },
];

export let mockCatalogObject = [
  {
    category: 'Personal Care',
    categoryId: 1,
    subCats: [{}],
    url: 'https://www.google.com',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
  {
    category: 'Soap',
    categoryId: 2,
    subCats: [{}],
    url: 'facebook.com',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
  {
    category: 'Health',
    categoryId: 3,
    subCats: [{}],
    url: 'https://www.google.com',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
  {
    category: 'Home',
    categoryId: 4,
    subCats: [{}],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
  {
    category: 'Category 5',
    categoryId: 5,
    subCats: [{}],
    description: 'Category 5 description',
  },
  {
    category: 'Category 6',
    categoryId: 6,
    subCats: [{}],
    description: 'Category 6 description',
  },
  {
    category: 'Category 7',
    categoryId: 7,
    subCats: [{}],
    description: 'Category 7 description',
  },
  {
    category: 'Category 8',
    categoryId: 8,
    subCats: [{}],
    description: 'Category 8 description',
  },
  {
    category: 'Category 9',
    categoryId: 9,
    subCats: [{}],
    description: 'Category 9 description',
  },
  {
    category: 'Category 10',
    categoryId: 10,
    subCats: [{}],
    description: 'Category 10 description',
  },
  {
    category: 'Category 11',
    categoryId: 11,
    subCats: [{}],
    description: 'Category 11 description',
  },
  {
    category: 'Category 12',
    categoryId: 12,
    subCats: [{}],
    description: 'Category 12 description',
  },
  {
    category: 'Category 13',
    categoryId: 13,
    subCats: [{}],
    description: 'Category 13 description',
  },
  {
    category: 'Category 14',
    categoryId: 14,
    subCats: [{}],
    description: 'Category 14 description',
  },
  {
    category: 'Category 15',
    categoryId: 15,
    subCats: [{}],
    description: 'Category 15 description',
  },
  {
    category: 'Category 16',
    categoryId: 16,
    subCats: [{}],
    description: 'Category 16 description',
  },
  {
    category: 'Category 17',
    categoryId: 17,
    subCats: [{}],
    description: 'Category 17 description',
  },
  {
    category: 'Category 18',
    categoryId: 18,
    subCats: [{}],
    description: 'Category 18 description',
  },
  {
    category: 'Category 19',
    categoryId: 19,
    subCats: [{}],
    description: 'Category 19 description',
  },
  {
    category: 'Category 20',
    categoryId: 20,
    subCats: [{}],
    description: 'Category 20 description',
  },
];

export const mockProductsObject = [
  {
    name: 'Soap Bar',
    productId: '15',
    price: 5.99,
    description: 'A bar of soap',
    parentCategoryId: [1, 2],
    subCategoryId: [],
    weight: '1.4',
    unit: 'oz',
    url: 'https://www.google.com',
  },
  {
    name: 'Shampoo',
    price: 5.99,
    description: 'A bottle of shampoo',
    productId: '15',
    parentCategoryId: [1],
    subCategoryId: [],
    weight: '1.4',
    unit: 'oz',
  },
];
