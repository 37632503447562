import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { UsersContext } from '../context/UsersProvider.js';
import { Login } from './Login';
import test1 from '../images/test1.jpg';
import test2 from '../images/test2.jpg';
import test3 from '../images/test3.jpg';

import landscape1 from '../images/landscape1.jpg';
import landscape2 from '../images/landscape2.jpg';
import landscape3 from '../images/landscape3.jpg';
import landscape4 from '../images/landscape4.jpg';
import landscape5 from '../images/landscape5.jpg';
import landscape6 from '../images/landscape6.jpg';
import landscape7 from '../images/landscape7.jpg';
// import landscape8 from '../images/landscape8.jpg';
// import landscape9 from '../images/landscape9.jpg';

import {
  HomeMainDiv,
  CenteredColumnBox,
} from '../components/styles/DarkStyles.js';
import { NavBar } from '../components/navagation/NavBar.js';
import { CatalogPage } from './CatalogPage.js';
import SideBar from '../components/navagation/SideBar.js';
import { Commet } from 'react-loading-indicators';

export const Home = () => {
  const [loading, setLoading] = useState(true);
  const { user, login, loginMenu, setLoginMenu } = useContext(UsersContext);

  const LandingContainer = styled.div`
    display: flex;
    justify-content: start;
    width: 100%;
    height: 100%;
  `;

  const HomeDiv = styled.div`
    overflow: auto;
    max-height: 100vh;
  `;

  const CenterScreenLoadingDiv = styled.div`
    ${loading ? 'display: block;' : 'display: none;'}
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    z-index: 100;
    // background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 0 22px rgba(0, 0, 0, 0.5);
    color: white;
    // padding: 180px;
    border-radius: 360px;
  `;

  const handleLoading = () => {
    setTimeout(() => {
      setLoginMenu('Login');
    }, 10000);
  };

  useEffect(() => {
    handleLoading();
  }, [1]);

  let images = [
    landscape1,
    landscape2,
    landscape3,
    landscape4,
    landscape5,
    landscape6,
    landscape7,
  ];

  const ImageRandomizer = (images) => {
    return images[Math.floor(Math.random() * images.length)];
  };

  return (
    <HomeDiv>
      <NavBar />
      <CenterScreenLoadingDiv
        style={{ display: loginMenu ? 'none' : 'block' }}
        className='loaderContainer'
      >
        <Commet
          className='loaderThing'
          color='red'
          size='large'
          text='Intializing Guardian'
          textColor='#ddd'
        ></Commet>
      </CenterScreenLoadingDiv>

      <HomeMainDiv className='background'>
        <img
          src={ImageRandomizer(images)}
          className='testImage1'
          alt='background'
        />
        <img
          src={ImageRandomizer(images)}
          className='testImage2'
          alt='background'
        />
        <div className='overlay'></div>
        <img
          src={ImageRandomizer(images)}
          className='testImage3'
          alt='background'
        />
        <img
          src={ImageRandomizer(images)}
          className='testImage4'
          alt='background'
        />
        <img
          src={ImageRandomizer(images)}
          className='testImage5'
          alt='background'
        />
        <img
          src={ImageRandomizer(images)}
          className='testImage6'
          alt='background'
        />
        <img
          src={ImageRandomizer(images)}
          className='testImage7'
          alt='background'
        />

        {login || loginMenu ? (
          <Login className='loginContainer' />
        ) : (
          <LandingContainer>
            <span className='sidebar'>{/* <SideBar /> */}</span>
            {/* <CatalogPage /> */}
          </LandingContainer>
        )}
      </HomeMainDiv>
    </HomeDiv>
  );
};
