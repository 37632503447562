import './App.css';
import styled from 'styled-components';
import { UsersProvider } from './context/UsersProvider';
import { useContext } from 'react';
import { Home } from './pages/Home';

const AppWrapper = styled.div``;

function App() {
  return (
    <UsersProvider>
      <AppWrapper>
        <Home />
      </AppWrapper>
    </UsersProvider>
  );
}

export default App;
