import React, { createContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { mockCartObject, mockUserObject } from './TestObjects';

const UsersContext = createContext();

const UsersProvider = ({ children }) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [login, setLogin] = useState(false);
  const [user, setUser] = useState();
  const [cart, setCart] = useState();
  const [error, setError] = useState(null);
  const [total, setTotal] = useState(0);
  const [loginMenu, setLoginMenu] = useState(false);

  useEffect(() => {}, []);

  !user ? setUser(mockUserObject) : console.log('no user found');

  return (
    <UsersContext.Provider
      value={{
        users,
        loading,
        login,
        user,
        cart,
        setLogin,
        setUser,
        setCart,
        loginMenu,
        setLoginMenu,
        error,
        total,
        setError,
        setTotal,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};

export { UsersProvider, UsersContext };
