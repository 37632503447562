import React, { useContext } from 'react';
import { UsersContext } from '../../context/UsersProvider';

import logo from '../../images/logomain.png';
import styled from 'styled-components';
import {
  CenteredColumnBox,
  CenteredRowBox,
  MyUtilityInput,
  MyUtilityLabel,
} from '../styles/DarkStyles';

export const NavBar = () => {
  const { loginMenu, setLoginMenu } = useContext(UsersContext);

  const NavBarDiv = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    background-color: rgba(0, 110, 90, 0.3);
    color: white;
    width: 100%;
    height: 7vh;
    min-height: 30px;
  `;

  const NavLinksDiv = styled.div`
    a {
      text-decoration: none;
      color: white;
      margin-left: 25px;
      opacity: 1;
    }
    @media (max-width: 768px) {
      display: none;
    }
  `;
  const NavAccountDiv = styled.div`
    display: flex;
    flex-direction: row;
    margin-right: 5%;
    margin-left: 10%;
    font-size: 0.8em;
    a {
      text-decoration: none;
      color: white;
    }
    padding-bottom: 15px;
    @media (max-width: 430px) {
      display: none;
      .links {
      }
    }
    align-items: center;
  `;

  const NavRowDiv = styled.div`
    display: flex;

    align-items: center;
    height: 100vh;
    margin-left: 5%;
    margin-right: auto;
    @media (max-width: 768px) {
      margin-right: 0px;
    }
  `;

  const NavButton = styled.button`
    background-color: #f44;
    color: #fff;
    margin-right: 10%;
    border: 3px solid #b44;
    clip-path: polygon(1% 25%, 15% 0, 98000% 200%, 0% 100%);
    width: 100px;
    height: 40px;
    &:hover {
      background-color: #833;
    }
  `;

  const FakeLinksButton = styled.button`
    font-size: 1.2em;
    background-color: rgba(0, 0, 0, 0);
    cursor: pointer;

    color: white;
    border: 1px solid rgba(225, 225, 225, 0.8);
    margin: 3px;
    border-radius: 5px;
    padding: 5px;
  `;

  const NavLogo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;

    align-items: center;
    x p {
      font-size: 2em;
      margin-left: 0px;
    }

    img {
      width: 75px;
      height: 75px;
      margin-right: 0px;
    }
  `;

  let NavButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    aligncontent: center;
    margin: 0px 25px 0px 10px;
    @media (max-width: 460px) {
      flex-direction: row;
      margin: 0px 0px 0px 0px;
    }
    @media (max-height: 1200px) {
      flex-direction: row;
    }
  `;

  let handleLoginToggle = (e) => {
    e.preventDefault();
    console.log(e.target.innerText);
    setLoginMenu(e.target.innerText);
  };

  return (
    <NavBarDiv style={{ color: 'white' }} className='navbarContainer'>
      <NavRowDiv>
        <NavLogo>
          <img src={logo} alt='logo' className='App-logo' />
          <div>
            <h2 className='company-name-nav'>PalTech</h2>
          </div>
        </NavLogo>
        {/* <MyUtilityLabel>Search</MyUtilityLabel>
        <MyUtilityInput type='text' placeholder='...fire away' /> */}
      </NavRowDiv>
      <NavLinksDiv>
        <a href='/about'>About</a>
        <a href='/careers'>Careers</a>
        <a href='/catalog'>Catalog</a>
        <a href='/careers-page'>Careers</a>
      </NavLinksDiv>

      <NavAccountDiv>
        <NavButtonContainer>
          <FakeLinksButton
            isLogin
            onClick={(e) => {
              handleLoginToggle(e);
            }}
          >
            Login
          </FakeLinksButton>
          <FakeLinksButton
            isRegister
            onClick={(e) => {
              handleLoginToggle(e);
            }}
          >
            Register
          </FakeLinksButton>
        </NavButtonContainer>

        <NavButton>Apply Now</NavButton>
      </NavAccountDiv>
    </NavBarDiv>
  );
};
