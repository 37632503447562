import React from 'react';
import styled from 'styled-components';

export const HomeMainDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #222;
  color: #fff;
  height: 100vh;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
`;

export const CenteredColumnBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(props) =>
    props.isLogin
      ? 'border: 1px solid white; border-radius: 10px; min-width: 15%;  padding: 5% 2.5% 5% 2.5%;'
      : ''};
`;

export const CenteredRowBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin-left: auto;
`;

export const MyUtilityButton = styled.button`
  background-color: #444;
  font-size: 1.5em;
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid #fff;
  margin: 10px;
  cursor: pointer;
`;

export const MyUtilityLabel = styled.label`
  margin: 5px;
`;

export const MyUtilityInput = styled.input`
  padding: 10px;
  border-radius: 5px;
  border: none;
  margin: 5px;
`;

export const MyColumnBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 10px;

  justify-content: center;
  // border: 1px solid green;
`;

export const MyRowBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  // border: 1px solid blue;
`;
export const MyUtilityForm = styled.form`
  display: flex;
  flex-direction: column;

  margin: 25px;
  justify-content: center;
  align-items: start;
`;

export const FakeLinksButton = styled.button`
  font-size: 1.2em;
  background-color: rgba(0, 0, 0, 0);
  cursor: pointer;

  color: white;
  border: 1px solid rgba(225, 225, 225, 0.8);
  margin: 3px;
  border-radius: 5px;
  padding: 5px;
`;
